

































































































































































































import { Vue, Component } from "vue-property-decorator";
import * as signalR from "@aspnet/signalr";
import AuthUser from "../../global-config/AuthUser";
import { ApiURIs } from "../../global-config/ApiList";

import Header from "../components/Header.vue";
import SideNav from "../components/SideNav.vue";
import ProfileWidget from "../components/ProfileWidget.vue";
import UserList from "./dashboard/UserList.vue";
import DoctorsActivity from "./dashboard/DoctorsActivity.vue";
import BuyAndSell from "./dashboard/BuyAndSell.vue";
import DonorsActivity from "./dashboard/DonorsActivity.vue";
import HospitalList from "./dashboard/HospitalList.vue";
import DocumentOverview from "./dashboard/DocumentOverview.vue";
import userService from "../service/UserService";
import employeeService from "../service/EmployeeService";

@Component({
  components: {
    Header,
    SideNav,
    ProfileWidget,
    UserList,
    DoctorsActivity,
    BuyAndSell,
    DonorsActivity,
    HospitalList,
    DocumentOverview,
  },
})
export default class AdminLayout extends Vue {
  public sidebarCollapse: boolean = true;
  public agentList: any = [];
  public employeeList: any = [];
  public AgentId: Number;
  public connection = new signalR.HubConnectionBuilder().withUrl(ApiURIs.baseUrl + "/chat").build();
  sidebarToggleState(state: any) {
    this.sidebarCollapse = state;
    return false;
  }
  connect() {
    // console.log("Connect Calling..");
    var role = 0;
    if (AuthUser.isLoggednIn()) {
      role = AuthUser.getRole();
    }
    if (this.connection.state != signalR.HubConnectionState.Connected) {
      this.connection
        .start()
        .then(() => this.connection.invoke("GetConnectionId"))
        .then((res) => {});
    }
    this.connection.serverTimeoutInMilliseconds = 120000; // 1 second
    // this.connection.keepAliveIntervalInMilliseconds = 120000;
  }
  AssignAsAgent(id: number) {
    userService.AssignAsAgent(id).then((e) => {
      // console.log(e);
      this.GetEmployeeForAgent();
    });
  }
  GetAllAgent() {
    userService.GetAgentList().then((e) => {
      // console.log(e.data.result);
      this.agentList = e.data.result;
    });
  }
  Revoke(id: number) {
    // console.log(id);
    userService.RemoveAsAgent(id).then((e) => {
      // console.log(e);
      this.GetAllAgent();
    });
  }
  GetEmployeeForAgent() {
    employeeService.GetEmployeeForAgent().then((e) => {
      // console.log(e.data.result);
      this.employeeList = e.data.result;
    });
  }
  mounted() {
    this.GetAllAgent();
  }

  openModal(content: any) {
    this.GetEmployeeForAgent();
    this.$bvModal.show(content);
  }
}
